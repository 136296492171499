import React from 'react'
import { isEvent, isTask, isTemplateRecur, Item } from 'src/types'
import Event from './Event'
import Task from './Task'
import HoverCard from 'src/components/common/HoverCard'
import TemplateRecur from './TemplateRecur'

const ItemHoverCard = ({ children, item, ...props }: { children: JSX.Element, item: Item, edit?: () => void }) => {
    return (
        <HoverCard openDelay={300} side={'right'} trigger={children} >
            {
                isTemplateRecur(item) && <TemplateRecur item={item} {...props} />
            }
            {
                isEvent(item) && <Event item={item} {...props}></Event>
            }
            {
                isTask(item) && <Task item={item} {...props}></Task>
            }
        </HoverCard>
    )
}

export default ItemHoverCard