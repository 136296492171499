import React, { forwardRef, useState } from 'react'
import { IconButton } from "@planda/design-system";
import { cva } from 'styled-system/css';
import { Cross1Icon } from '@radix-ui/react-icons';
import { useGlobalPanel } from '../components/usePanel';
import { motion, useMotionValue, useTransform } from 'framer-motion'
import { useContainerDimensions } from '@/hooks/useContainerDimensions';
import { SHEET_WIDTH, SHEET_HEIGHT, fmDragHandle, closeIcon } from './styles';
import MotionSheetContent from '@/components/common/Sheet';

const animationRight = {
    initial: {
        right: -1 * SHEET_WIDTH
        // x: '100%',
    },
    animate: {
        right: 0
        // x: `calc((100% - ${SHEET_WIDTH}px))`,
    },
    exit: {
        // x: '100%',
        right: -1 * SHEET_WIDTH,
        transition: {
            duration: 0.2,
        },
    },
    transition: {
        duration: 0.2,
        ease: "easeInOut",
    },
}

const animationBottom = {
    initial: {
        bottom: -1 * SHEET_HEIGHT
    },
    animate: {
        bottom: 0
    },
    exit: {
        bottom: -1 * SHEET_HEIGHT,
        transition: {
            duration: 0.2,
        },
    },
    transition: {
        duration: 0.2,
        ease: "easeIn",
    },
}


export interface ChildrenProps {
    setDragIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    panelHeight: number;
    draggable: boolean;
}

interface ParentWrapperProps {
    children: (title: ChildrenProps) => JSX.Element;
    align?: 'left' | 'right' | 'top' | 'bottom';
    panelKey: string;
}

const sheetStretch = cva({
    variants: {
        isVertical: {
            true: {
                height: '100%',
            },
            false: {
                width: '100%',
            }
        }
    }
})

// only align-right and align-bottom are implemented so far
const SheetPanel = forwardRef<any, ParentWrapperProps>(({ children, align = 'right', panelKey }, constraintsRef) => {
    const { panel, setPanel } = useGlobalPanel()
    const [draggable, setDraggable] = useState(true)
    const [dragIsDisabled, setDragIsDisabled] = useState(false)

    const isVertical = align === 'left' || align === 'right'

    const x = useMotionValue(0);
    const width = useTransform(x, (x) => `${SHEET_WIDTH - x}px`);
    const y = useMotionValue(0);
    const height = useTransform(y, (x) => `${SHEET_HEIGHT - x}px`);

    const [startOffsetX, setStartOffsetX] = useState(0);
    const [startOffsetY, setStartOffsetY] = useState(0);
    // @ts-expect-error
    const { width: containerWidth, height: containerHeight } = useContainerDimensions(constraintsRef)

    const panelHeight = SHEET_HEIGHT - y.get()

    return (
        <MotionSheetContent drag={draggable && !dragIsDisabled}
            key={panelKey}
            isMounted={panel === panelKey}
            {...(isVertical ? animationRight : animationBottom)}
            style={isVertical ? { width } : { height }}
            className={sheetStretch({ isVertical })}
            dragMomentum={false}
            isVertical={isVertical}
            dragConstraints={isVertical ? {
                left: SHEET_WIDTH + x.get() - containerWidth, right: 0, top: 0, bottom: 0
            } : { top: SHEET_HEIGHT + y.get() - containerHeight, right: 0, left: 0, bottom: 0 }}
        >
            <motion.div
                className={fmDragHandle({ align })}
                title="drag to resize"
                onPan={(e, info) => {
                    setDraggable(false);
                    isVertical ? x.set(startOffsetX + info.offset.x) : y.set(startOffsetY + info.offset.y)
                }}
                onPanEnd={(e, info) => {
                    setDraggable(true);
                    isVertical ? setStartOffsetX(startOffsetX + info.offset.x) : setStartOffsetY(startOffsetY + info.offset.y)
                }}
            />
            {children({ setDragIsDisabled, panelHeight, draggable })}
            <IconButton className={closeIcon} onClick={() => setPanel(null)} variant="ghost">
                <Cross1Icon />
            </IconButton>
        </MotionSheetContent>
    )
})
SheetPanel.displayName = 'SheetPanel';
export default SheetPanel