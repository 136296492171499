import React, { forwardRef, useRef } from 'react'
import { Flex } from "@planda/design-system";
import { css } from 'styled-system/css';
import PlandaTimegrid, { PlandaTimegridProps } from 'src/features/calendars/common-use/Timegrid';
import { startOfDay } from 'date-fns';
import useDate from '@/hooks/useDate';
import { formatDay } from '@planda/utils';
import { PanInfo } from 'framer-motion'
import { useContainerDimensions } from '@/hooks/useContainerDimensions';
import { SHEET_WIDTH } from './styles';
import { PartialK } from '@/types';
import TriangleNav from '@/components/common/TriangleNav';
import SheetPanel from './SheetPanel';

export const ResizableTimeGrid = ({ onPan, onPanEnd, days: givenDays, isResizing, ...timegridProps }: {
    onPan?: (e: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void,
    onPanEnd?: (e: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void, isResizing?: boolean,
} & PartialK<PlandaTimegridProps, 'firstDay' | 'days'>) => {
    const { date, changeDay } = useDate()
    const containerRef = useRef<HTMLDivElement>(null)

    const { width: panelWidth } = useContainerDimensions(containerRef)
    const getDays = () => {
        return givenDays ?? Math.max(Math.ceil((panelWidth / SHEET_WIDTH) ** 1.5), 1)
    }

    // const [days, setDays] = useState(getDays())

    const days = getDays()

    // useEffect(() => {
    //     console.log("isResizing", days, panelWidth)
    //     setDays(getDays())
    //     console.log("isResizingnext", getDays())
    //   }, [isResizing]);

    return (
        <div ref={containerRef} className={css({
            maxHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            width: '100%',
            background: 'inherit',
        })}>
            <Flex className={css({ marginTop: 15 })} gap={'3'} align={'center'} justify='center' >
                <TriangleNav className={css({ marginRight: 3 })} direction={"left"} fn={() => changeDay(-days)} />
                <label>
                    {formatDay(date)}
                    {/* {format(date, 'do , y ' + FORMAT_TIME)} */}
                    {/* 7th, 2022 12:31pm  */}
                </label>
                {/* <Link href={VIEWS[(VIEWS.indexOf(view as string) + 1) % VIEWS.length]} >
                <ViewToggle title="Click to switch view" shape={'rectangle'} ><MagicWandIcon /></ViewToggle>
            </Link> */}
                <TriangleNav className={css({ marginLeft: 3 })} direction={"right"} fn={() => changeDay(days)} />
            </Flex>
            <PlandaTimegrid
                firstDay={startOfDay(date).getTime()}
                days={days}
                {...timegridProps}
            />
        </div>
    )
}

// TODO: a lot of day panel should now be removable since moved in ResizableTimeGrid
const DayPanel = forwardRef<any, {}>(({ }, constraintsRef) => {
    return (
        <SheetPanel panelKey='timegrid-day' ref={constraintsRef} align={'right'}>
            {({ setDragIsDisabled, draggable }) => (
                <ResizableTimeGrid
                    containerClassName={css({ padding: '20px 0px 0px 20px' })}
                    setIsDragging={setDragIsDisabled}
                    isResizing={!draggable}
                />
            )}
        </SheetPanel>
    )
})
DayPanel.displayName = 'DayPanel';
export default DayPanel