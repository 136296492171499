'use client'
import { useState } from 'react'

export default function useDate(interval = 1000) { // interval is in seconds
    const [date, setDate] = useState(new Date())

    // useEffect(() => {
    //     const curr = new Date()
    //     const d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), curr.getHours(), curr.getMinutes(), curr.getSeconds())
    //     if (interval) {
    //         const intervalTimer = setInterval(() => setDate(d), interval * 1000);
    //         return () => {
    //             clearInterval(intervalTimer);
    //         };
    //     }
    //     return
    // }, [date]);

    function changeDay(increment = 1) {
        setDate(d => {
            const newDate = new Date(d)
            newDate.setDate(newDate.getDate() + increment)
            return newDate;
        })
    }

    function changeMonth(increment = 1) {
        setDate(d => {
            return new Date(d.getFullYear(), d.getMonth() + increment, 1)
        })
    }

    function setDay(date: Date | number) {
        setDate(new Date(date))
    }

    return { date, changeDay, changeMonth, setDay }
}