import { ArrowDownIcon } from '@radix-ui/react-icons'
import { range } from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import { styled } from 'styled-system/jsx'
import Cell from './Cell'
import { LayoutUnit, NewItemInfo } from '../../types'
import { isSameMonth, startOfDay } from 'date-fns'
// import { ScrollArea, ScrollAreaCorner, ScrollAreaScrollbar, ScrollAreaThumb, ScrollAreaViewport } from 'styles'

const Row = ({ start, activeDate, calendarId, children, handleDoubleClick, hasExtraSpace, layoutUnit, layoutVal }:
    {
        start: Date | number, // start of week, end is 7 days after start
        activeDate: number | Date,
        calendarId: string,
        children?: JSX.Element[],
        handleDoubleClick: (item: NewItemInfo) => void,
        hasExtraSpace?: boolean,
        layoutUnit: LayoutUnit, layoutVal: number,
    }) => {

    const daysInRow = layoutUnit === 'day' ? layoutVal : 7
    const blocks = useMemo(() => {
        const d = new Date(start)

        const blocks = []
        for (let count = 0; count < daysInRow; count++) {
            blocks.push(<Cell handleDoubleClick={handleDoubleClick} calendarId={calendarId} day={startOfDay(d)} key={d.getTime()} isGray={!isSameMonth(activeDate, d)}></Cell>)
            d.setDate(d.getDate() + 1)
        }

        return blocks;
    }, [start])

    const scrollEnd = useRef(null)
    const [overflow, setOverflow] = useState(false)

    const scrollToBottom = () => {
        // @ts-expect-error
        scrollEnd.current?.scrollIntoView({ behavior: "smooth" })
    }

    const canScrollDown = (element: HTMLDivElement) => {
        return Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) > 3.0
    }

    const handleScroll = (event: any) => {
        if (event.target) {
            setOverflow(canScrollDown(event.target))
        }
    }

    return (
        <Wrapper>
            <ScrollArea onScroll={handleScroll} id='row-scroll-area' ref={newRef => setOverflow(newRef ? canScrollDown(newRef) : false)} >
                <Overlay>
                    {blocks}
                </Overlay>
                <ItemGrid style={
                    {
                        ...(hasExtraSpace && { gridAutoRows: 'minmax(1.7rem,auto)' }),
                        ...(daysInRow !== 7 && { gridTemplateColumns: `repeat(${daysInRow},1fr)` }),
                    }}
                >
                    {
                        range(daysInRow).map(x => (
                            <div key={x} ></div>
                        ))
                    }
                    {children}
                </ItemGrid>
                <div ref={scrollEnd} />
                {
                    overflow &&
                    <OverflowIndicator onClick={() => scrollToBottom()} />
                }
            </ScrollArea>
        </Wrapper >
    )
}

export default Row

const Wrapper = styled('div', {
    base: {
        position: "relative",
        boxSizing: "border-box",
        maxHeight: "100%",
        minWidth: 0,
        minHeight: 0
    }
})

const ScrollArea = styled('div', {
    base: {
        content: "cahh",
        size: "100%",
        overflowY: "auto",
        "@supports (overflow: overlay)": {
            overflowY: "overlay"
        },
        scrollBehavior: "smooth",
        padding: 0,
        position: "relative"
    }
})

const ItemGrid = styled('section', {
    base: {
        position: "absolute",
        display: "grid",
        gap: "0.1rem 0",
        gridTemplateRows: "2rem",
        gridAutoRows: "1.4rem",
        minWidth: 0,
        minHeight: 0,
        pointerEvents: "none",
        width: "100%",
        top: 0,
        gridTemplateColumns: "repeat(7,1fr)",
        gridAutoFlow: "dense"
    }
})

const Overlay = styled('section', {
    base: {
        position: "sticky",
        size: "100%",
        display: "flex",
        flexDirection: "row",
        background: "$outline",
        gap: "0 0.1rem",
        justifyItems: "stretch",
        top: 0,
        zIndex: 1
    }
})

const OverflowIndicator = styled(ArrowDownIcon, {
    base: {
        bottom: 3,
        position: "absolute",
        marginInline: "auto",
        left: "0",
        right: "0",
        opacity: 0.5,
        zIndex: 11,
        "&:hover": {
            opacity: 1
        }
    }
})