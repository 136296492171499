import { cva, css } from 'styled-system/css'
// these are in panda.config.ts too
export const SHEET_WIDTH = 350
export const SHEET_HEIGHT = 320
export const DRAG_HANDLE_WIDTH = 8

export const fmDragHandle = cva({
    base: {
        position: 'absolute',
        height: '100%',
        left: 0,
        width: 'dragHandleWidth',
        transition: 'backgroundColor 0.2s',
        backgroundColor: '$gray3',
        zIndex: 100,
        '&:hover': {
            backgroundColor: '$gray4',
        },
        '&:active': {
            backgroundColor: '$gray6',
        },
    },
    variants: {
        align: {
            right: {
                left: 0,
                cursor: 'col-resize',
                height: '100%',
                width: 'dragHandleWidth',
            },
            bottom: {
                top: 0,
                cursor: 'row-resize',
                width: '100%',
                height: 'dragHandleWidth',
            },
            left: {
                right: 0,
                cursor: 'col-resize',
                height: '100%',
                width: 'dragHandleWidth',
            },
            top: {
                bottom: 0,
                cursor: 'row-resize',
                width: '100%',
                height: 'dragHandleWidth',
            },
        },
    },
})


export const closeIcon = css({
    position: "absolute",
    top: "$2",
    right: "$2"
})
