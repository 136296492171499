import React from 'react'
import { Task as TaskType } from 'src/types'
import { format } from 'date-fns'
import Link from 'next/link'
import { ArrowRightIcon } from '@radix-ui/react-icons'
import { styled } from 'styled-system/jsx'
import { Group, IconBar } from './styles'
import { Unimportant } from 'src/components/common/commonStyles'
import Edit from './components/Edit'

const DATE_FORMAT = 'MMM. d, y'

const Task = ({ item, edit }: { item: TaskType, edit?: () => void }) => {
    return (
        <Group>
            <IconBar>
                <Edit edit={edit} />
            </IconBar>
            <label>Name: {item.name}</label>
            {item.dateStart && <Unimportant>Due on {format(item.dateStart, DATE_FORMAT)}</Unimportant>}
            {/* {item.createdAt && <Unimportant>Created on {format(item.createdAt, DATE_FORMAT)}</Unimportant>} */}
            {/* need date stuff !!! */}
            {item.notes && <label>{item.notes}</label>}
            {item.links && <ul className='link'>{item.links.map((x, i) => <li key={'link' + i} ><a href={x.url}>{x.text}</a></li>)}</ul>}
            {item.subtasks && <ul>{item.subtasks.map((x, i) => <li key={x.name + i} style={{ textDecoration: x.completed ? 'line-through' : 'none' }} >{x.name}</li>)}</ul>}
            {/* <InfoCircledIcon /> */}
            {/* ${item.category || ''} */}
            {/* ?activeId=i.task.yZWQRGPs4q5g */}
            <Link href={`/list${item.category.length > 1 ? '/' + item.category : ''}?activeId=${item.id}`} ><GoToLink>Go to <ArrowRightIcon /></GoToLink></Link>
        </Group>
    )
}

export default Task

const GoToLink = styled('label', {
    base: {
        boxSizing: "border-box",
        borderBottom: "$borderThin",
        borderColor: "transparent",
        "&:hover": {
            borderColor: "$outline"
        }
    }
})