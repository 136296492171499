import { mutate } from "swr";
import { GOOGLE_CAL_KEY } from "@constants/user";
import localforage from "localforage";
import { signIn } from "next-auth/react";
import { fetchGoogleCalendarEvents } from "./fetch";
import { jsonParse } from "@planda/utils";
import { GOOGLE_CALENDAR_SCOPES } from "@constants/external";

export async function fetchSync(url: string) {
    return fetch(url, { method: 'POST', cache: 'no-store' }).then(async (res) => {
        if (res.ok) {
            // need to mutate
            mutate(
                key => typeof key === 'string' && (
                    key.startsWith('/api/date?start=') ||
                    key.startsWith('/api/main/category')
                ),
                undefined
            )
            localforage.setItem(GOOGLE_CAL_KEY, true)
            return jsonParse(await res.text())
        } else if (res.status === 403) {
            // prompt resignin to google
            const confirmed = window.confirm("You'll have to reauthorize Google Calendar access every 7 days until I get Planda verified by Google. Apologies for the inconvenience. You can disable Google Calendar sync if you wish from where you authorized it. Or click cancel to disable it now.")
            if (!confirmed) {
                await fetch('/api/external/calendar/google', { method: 'DELETE' })
            }
            else {
                await signIn('google', {}, { scope: GOOGLE_CALENDAR_SCOPES.join(' '), prompt: 'consent', access_type: 'offline' })
                fetchGoogleCalendarEvents()
            }
        } else {
            // console.error(res.status, res.statusText);
            return undefined
        }
    })
}
