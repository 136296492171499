import { cva, cx } from 'styled-system/css'
import { motion, HTMLMotionProps, AnimatePresence } from 'framer-motion'

export const sheetContent = cva({
    base: {
        position: 'fixed',
        boxShadow: '{colors.$shadowLight} 0 0 38px -10px, {colors.$shadowDark} 0 0 35px -15px',
        //TODO: check if this needed stitches change '-webkit-transform': 'translateZ(0)',
        backgroundColor: '$loContrast',
        zIndex: 100,
        overflow: 'visible',
        minWidth: 'max-content',
        resize: 'horizontal',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    variants: {
        isVertical: {
            true: {
                width: 'sheetWidth', top: 0,
                bottom: 0,
            },
            false: { height: 'sheetHeight', left: 0, right: 0 },
        },
    },
    defaultVariants: {
        isVertical: true,
    }
})

const MotionSheetContent = ({ children, className, isVertical, isMounted = true, ...props }: HTMLMotionProps<'div'> & { isVertical?: boolean, isMounted?: boolean }) => {
    return (
        <AnimatePresence mode='wait'>
            {isMounted && <motion.div {...props} className={cx(sheetContent({ isVertical }), className)}>{children}</motion.div>}
        </AnimatePresence>
    )
}

export default MotionSheetContent