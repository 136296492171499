import { BareFlexCol } from '@/components/common/commonStyles'
import Spinner from '@/components/common/Spinner'
import ItemHoverCard from '@/components/specific/item-hover-card'
import { useGetUserQuery } from '@/redux/features/api/apiSlice'
import React, { useCallback, useState } from 'react'
import ItemForm from 'src/features/forms/item'
import { DatedItem, DayOfWeekNum } from 'src/types'
import DayGrid from '../daygrid'
import { CalendarItem, LayoutUnit, NewItemInfo } from '../types'
import { getDaygridLayoutInfo } from '../utils'
import useCalendarItems from './useCalendarItems'
import { useUpdatePlannerItemMutation } from '@/redux/features/api/apiSlice'
import { usePutPlannerItem } from '@/hooks/main/category/usePutPlannerItem'

const Wrapper = ({ item: calItem, children, edit }: { item: CalendarItem, children: JSX.Element, edit?: () => void }) => {
    const item = calItem as DatedItem
    return (
        <ItemHoverCard item={item} edit={edit}>
            <BareFlexCol>
                {children}
            </BareFlexCol>
        </ItemHoverCard>
    )
}

const PlandaDaygrid = React.memo(({ date, layoutUnit = 'month', layoutVal = 1, setIsDragging }: {
    date: Date, layoutUnit?: LayoutUnit, layoutVal?: number,
    setIsDragging?: (isDragging: boolean) => void
}) => {
    const { start, end } = getDaygridLayoutInfo(layoutUnit, layoutVal, date, 1)
    const { data: user } = useGetUserQuery()
    const { putItem } = usePutPlannerItem()
    const [updateItem] = useUpdatePlannerItemMutation({ fixedCacheKey: 'planner-list' })
    const { calendarFormat, createItemContextMenu } = useCalendarItems(start.getTime(), end.getTime())

    const [openForm, setOpenForm] = useState(false)
    const [defaultFormValues, setDefaultFormValues] = useState({})


    const handleOpenForm = (defaultValues?: NewItemInfo) => {
        setDefaultFormValues({ ...defaultValues })
        setOpenForm(true)
    }

    const edit = useCallback((id: string, updates: any) => updateItem({ id, updates: { set: updates } }), [updateItem])

    if (!user) return <Spinner />

    return (
        <>
            <ItemForm
                open={openForm} setOpen={setOpenForm}
                defaultType='event'
                defaultValues={defaultFormValues} />
            <DayGrid
                activeDay={date}
                layoutUnit={layoutUnit}
                layoutVal={layoutVal}
                weekStartsOn={(user.weekStartsOn % 7) as DayOfWeekNum}
                data={calendarFormat || []}
                handleDoubleClickDate={handleOpenForm}
                handleDateChange={edit}
                handleAddItem={putItem}
                // `/api/date?start=${start.getTime()}&end=${end.getTime()}&convertTemplateRecurs=false`
                contextMenu={(item, calendarItem) => createItemContextMenu(item, calendarItem, handleOpenForm)}
                Wrapper={Wrapper}
                setIsDragging={setIsDragging}
            // contextMenu={(item) => createItemContex. .tMenu(item as Item, handleOpenForm, mutate)} // NOTE: difference between Item and Calendar item is name not requried in TemplateRecur
            />
        </>
    )
})
PlandaDaygrid.displayName = 'PlandaDaygrid'
export default PlandaDaygrid
