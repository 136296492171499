import { range } from 'lodash'
import React from 'react'
import { styled } from 'styled-system/jsx'
import { DayOfWeek } from '../../types'

const DayOfWeekLabels = ({ firstDayOfWeek, days }: { firstDayOfWeek: DayOfWeek, days: number }) => {
    const daysOfTheWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]

    const daysOfTheWeekLabels = range(Math.min(7, days)).map(x => {
        const index = (x + firstDayOfWeek) % 7
        return <DayOfTheWeek key={index} isSameDay={index == new Date().getDay()}>{daysOfTheWeek[index]}</DayOfTheWeek>
    })

    return (
        <Labels>{daysOfTheWeekLabels}</Labels>
    )
}

export default DayOfWeekLabels

const DayOfTheWeek = styled('label', {
    variants: {
        isSameDay: {
            true: {
                color: "$primary8"
            }
        }
    },
    base: {
        background: "$monthbg",
        padding: "2px",
        paddingTop: "0",
        flex: "1",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        textAlign: "justify",
        textJustify: "inter-character",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingBottom: 6
    }
})

const Labels = styled('section', {
    base: {
        display: "flex",
        flexDirection: "row",
        position: "relative",
        background: "$outline",
        gap: "0 0.1rem",
        justifyItems: "stretch"
    }
})
