import { RefObject, useEffect, useState } from 'react'

export const useContainerDimensions = (myRef: RefObject<any>) => {
    const getDimensions = () => ({
        width: myRef?.current?.offsetWidth || 0,
        height: myRef?.current?.offsetHeight || 0,
    })

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (myRef.current) {
            setDimensions(getDimensions())
        }

        if (!myRef.current) return
        window.addEventListener('resize', handleResize)
        const resizeObserver = new ResizeObserver(() => {
            // Do what you want to do when the size of the element changes
            handleResize()
        })
        resizeObserver.observe(myRef.current)
        return () => {
            resizeObserver.disconnect()
            window.removeEventListener('resize', handleResize)
        }
    }, [myRef])

    return dimensions
}
