import { useDroppable } from '@dnd-kit/core'
import { PlusIcon } from '@radix-ui/react-icons'
import { css } from 'styled-system/css';
import { endOfDay, isToday } from 'date-fns'
import React from 'react'
import { styled } from 'styled-system/jsx'
import { NewItemInfo } from '../../types'

/**
 * @param day day cell is for (ex. Dec 15, 2020)
 * @returns Individual cell for daygrid
 */
const Cell = ({
    day,
    calendarId,
    isGray,
    handleDoubleClick,
}: {
    day: Date, // hours should be set to 0
    calendarId: string,
    isGray: boolean,
    handleDoubleClick: (item: NewItemInfo) => void,
}) => {
    const defaults = {
        task: { dateStart: day.getTime() },
        event: { dateStart: day.getTime(), dateEnd: endOfDay(day).getTime() },
    }

    const { setNodeRef, isOver } = useDroppable({
        id: day.getTime(),
        data: {
            scope: calendarId,
            start: day.getTime(),
            defaults
            // onDrop can call handleMove if existing event, or creates new event with
        },
    });
    const today = isToday(day)
    return (
        <Flex ref={setNodeRef} isOver={isOver} title='double click to add' isGray={isGray} isSameDayOfWeek={day.getDay() === new Date().getDay()}>
            <Block isToday={today} onDoubleClick={() => handleDoubleClick({ ...defaults.event })}>
                <DayOfMonth >
                    {today && <Circle />}
                    <span className={css({ zIndex: 1 })}>
                        {day.getDate()}
                    </span>
                </DayOfMonth>
            </Block>
            <AddButton data-label={'button'} onClick={() => handleDoubleClick({ ...defaults.event })} />
        </Flex>
    )
}

export default Cell

const AddButton = styled(PlusIcon, {
    base: {
        zIndex: 1,
        padding: 5
    }
})

const Block = styled('div', {
    variants: {
        isToday: {
            true: {
                color: "$primary11"
            }
        }
    },
    base: {
        content: "cell",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flex: 1
    }
})

const Circle = styled('div', {
    base: {
        content: "",
        width: "90%",
        aspectRatio: "1 / 1",
        boxSizing: "border-box",
        backgroundColor: "$secondary2",
        opacity: 0.8,
        position: "absolute",
        borderRadius: "50%"
    }
})

const Flex = styled('div', {
    base: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        background: '$daygridBg',
        [`& [data-label="button"]`]: {
            display: 'none'
        },
        '&:hover': {
            [`& [data-label="button"]`]: {
                display: 'block'
            },
        },
    },
    variants: {
        isGray: {
            true: {
                '& div': {
                    opacity: 0.5,
                }
            }
        },
        isSameDayOfWeek: {
            true: {
                background: '$daygridToday', // controlled by primary if daygridToday doesn't exist
            }
        },
        isOver: {
            true: {
                background: '$primary5'
            }
        }
    }
})

const DayOfMonth = styled('div', {
    base: {
        position: "relative",
        borderBottom: "$borderThin",
        width: "max-content",
        padding: "0.3rem 0.4rem 0 0.4rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
})