// import { Group } from '@/components/common/commonStyles'
import EventCronParser from 'event-cron-parser'
import { css } from 'styled-system/css';
import React from 'react'
import { TemplateRecur as TemplateRecurType } from 'src/types'
import { Group, IconBar } from './styles'
import Edit from './components/Edit'

const TemplateRecur = ({ item, edit }: { item: TemplateRecurType, edit?: () => void }) => {
  return (
    <Group>
      <IconBar>
        <Edit edit={edit} />
      </IconBar>
      <h3 className={css({ textAlign: 'center' })}>{item.name}</h3>
      <Recurring cron={item.cron} start={item.dateStart} end={item.dateEnd || null} />
      {item.notes && <label>{item.notes}</label>}
      {item.links && <ul className='link'>{item.links.map((x, i) => <li key={'link' + i}><a href={x.url}>{x.text}</a></li>)}</ul>}
    </Group>
  )
}

export default TemplateRecur

export const Recurring = ({ cron, start, end }: { cron: string, start: number, end: number | null }) => {
  // !!! recurring events can not have dateEnd, but events must have
  const cronObject = new EventCronParser(cron)
  // const { parsedCron } = cronObject
  // const [minutes, hours, dayOfMonth, month, dayOfWeek, year, duration] = cron.split(' ')
  // const startTime = new Date()
  // const interval = { start, end }
  // const day = new Date()
  // startTime.setHours(parseInt(hours))
  // startTime.setMinutes(parseInt(minutes))
  // const endTime = startTime.getTime() + parseInt(duration)
  return (
    <Group>
      {<label>Occurs {cronObject.desc()} </label>}

      {/* <label>{format(startTime, 'h:mmaaa')}  - {format(endTime, 'h:mmaaa')} </label> */}
    </Group>
  )
}