import { IconButton } from '@planda/design-system'
import { Pencil1Icon } from '@radix-ui/react-icons'
import React from 'react'

const Edit = ({ edit }: { edit?: () => void }) => {
  if (!edit) return (<></>)
  return (
    <IconButton onClick={() => edit()} ><Pencil1Icon /></IconButton>
  )
}

export default Edit