import React, { forwardRef } from 'react'
import { Flex } from "@planda/design-system";
import { css } from 'styled-system/css';
import useDate from '@/hooks/useDate';
import { formatDay } from '@planda/utils';
import PlandaDaygrid from 'src/features/calendars/common-use/Daygrid';
import { SHEET_HEIGHT } from './styles';
import TriangleNav from '@/components/common/TriangleNav'
import SheetPanel from './SheetPanel';

// const animation = {
//     initial: {
//         bottom: -1 * SHEET_HEIGHT
//         // x: '100%',
//     },
//     animate: {
//         bottom: 0
//         // x: `calc((100% - ${SHEET_WIDTH}px))`,
//     },
//     exit: {
//         // x: '100%',
//         bottom: -1 * SHEET_HEIGHT,
//         transition: {
//             duration: 0.2,
//         },
//     },
//     transition: {
//         duration: 0.2,
//         ease: "easeInOut",
//     },
// }

// const FMDragHandle = styled(motion.div, {
//     base: {
//         cursor: 'row-resize',
//         position: 'absolute',
//         width: '100%',
//         left: 0,
//         height: 'dragHandleWidth',
//         transition: "backgroundColor 0.2s",
//         backgroundColor: '$gray3',
//         zIndex: 100,
//         '&:hover': {
//             backgroundColor: '$gray4'
//         },
//         '&:active': {
//             backgroundColor: '$gray6'
//         }
//     }
// })

const DayGridPanel = forwardRef<any, {}>(({ }, constraintsRef) => {
    const { date, changeDay } = useDate()

    return (
        <SheetPanel panelKey='daygrid-day' ref={constraintsRef} align={'bottom'}>
            {({ setDragIsDisabled, panelHeight }) => {
                const days = Math.max(Math.ceil((panelHeight / SHEET_HEIGHT) ** 1.5), 1)
                return (
                    <>
                        <Flex className={css({ marginTop: 15, marginBottom: 10 })} gap={'3'} align={'center'} >
                            <TriangleNav className={css({ marginLeft: 3 })} direction={"left"} fn={() => changeDay(-7)} />
                            <label>
                                {formatDay(date)}
                                {/* {format(date, 'do , y ' + FORMAT_TIME)} */}
                                {/* 7th, 2022 12:31pm  */}
                            </label>
                            {/* <Link href={VIEWS[(VIEWS.indexOf(view as string) + 1) % VIEWS.length]} >
                            <ViewToggle title="Click to switch view" shape={'rectangle'} ><MagicWandIcon /></ViewToggle>
                        </Link> */}
                            <TriangleNav className={css({ marginLeft: 3 })} direction={"right"} fn={() => changeDay(7)} />
                        </Flex>
                        <PlandaDaygrid layoutUnit={'week'} layoutVal={days} date={date} setIsDragging={setDragIsDisabled} />
                    </>
                )
            }}
        </SheetPanel>
    )
})
DayGridPanel.displayName = 'DayGridPanel';
export default DayGridPanel