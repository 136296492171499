import { ContextMenuUnit } from '@/components/common'
import { DragOverlay, useDndMonitor } from '@dnd-kit/core'
import { addDays, startOfDay, addWeeks, endOfDay, getWeeksInMonth, startOfMonth, startOfWeek, isAfter } from 'date-fns'
import EventCronParser from 'event-cron-parser'
import { groupBy, range } from 'lodash'
import React, { useMemo, useState } from 'react'
import { styled } from 'styled-system/jsx'
import useData from '../hooks/useData'
import { CalendarItem, CalItemWrapperType, DayOfWeek, isLayoutUnit, isRecurringEvent, LayoutUnit, NewItemInfo } from '../types'
import { getPositionOfDayOfWeek, handleCronDayOfWeekChange, moveDateOrIntervalDay, numberOfDaysSpanned } from '../utils'
import DayOfWeekLabels from './components/DayOfWeekLabels'
import Item, { ItemStyle } from './components/Item'
import Row from './components/Row'
import { useSearchParams } from 'next/navigation'
import { css } from 'styled-system/css'
import { categoryColorDynamic } from '@/utils/categories'

/**
 * TODOs
 * ROW: passes items in as children now
 * dndmonitor
 * context menus
 * @param param0
 * @returns
 */
const DayGrid = ({
    activeDay,
    layoutUnit, layoutVal,
    weekStartsOn,
    data,
    handleAddItem,
    handleDoubleClickDate,
    handleDateChange,
    calendarId = 'calendar',
    Wrapper,
    contextMenu,
    setIsDragging,
}: {
    activeDay: Date,
    // layout: DayGridLayout, // ex. ['weeks', 1] or ['months', 1] or ['days', 3]
    layoutUnit: LayoutUnit, layoutVal: number,
    weekStartsOn: DayOfWeek,
    data: CalendarItem[],
    handleAddItem: (item: CalendarItem) => any,
    handleDoubleClickDate: (day: NewItemInfo) => any,
    handleDateChange: (id: string, updates: { dateStart: number, dateEnd?: number, cron?: string }) => any,
    calendarId?: string,
    Wrapper?: CalItemWrapperType,
    contextMenu?: (item: any, calendarItem: CalendarItem) => ContextMenuUnit[],
    setIsDragging?: (isDragging: boolean) => void,
}) => {
    if (!isLayoutUnit(layoutUnit)) throw new Error('invalid layout type')
    const days = layoutUnit === 'day' ? layoutVal : layoutUnit === 'week' ? layoutVal * 7 : getWeeksInMonth(activeDay, { weekStartsOn }) * 7 * layoutVal

    const startDate = useMemo(() => {
        if (layoutUnit === 'day' && layoutVal < 7) return startOfDay(activeDay)
        if (layoutUnit === 'week') return startOfWeek(activeDay, { weekStartsOn })
        return startOfWeek(startOfMonth(activeDay), { weekStartsOn })
    }, [layoutUnit, layoutVal, activeDay, weekStartsOn])
    const endDate = useMemo(() => endOfDay(addDays(startDate, days - 1)), [startDate, days])

    const { calEvents, editCalItem: editItem, addCalItem: addItem, eventSet, tasks, find } = useData(data, startDate, endDate, 'week', weekStartsOn)
    const [activeDragItem, setActiveDragItem] = useState<CalendarItem | null>(null)
    /**TODO: setActiveDragItem causing too many rerenders */

    const searchParams = useSearchParams()
    const active = searchParams?.get('active')

    useDndMonitor({
        onDragStart(event) {
            setIsDragging?.(true)
            if (event.active.data.current?.scope === calendarId)
                setActiveDragItem(find(event.active.id as string))
        },
        onDragMove(event) { },
        onDragOver(event) { },
        onDragEnd(event) {
            setIsDragging?.(false)
            setActiveDragItem(null)
            const { active, over, delta } = event;
            if (Math.abs(delta.x) < 5 && Math.abs(delta.y) < 5) return
            if (!over || !over.data.current || !active.data.current || typeof active.id !== 'string') return
            if (over.data.current.scope !== calendarId) return
            const rootItem = find(active.id) as CalendarItem | undefined
            const overData = over.data.current
            const activeData = active.data.current

            if (activeData.type !== 'task' && activeData.type !== 'event') return

            if (!rootItem) {
                const item = { ...overData.defaults[activeData.type], ...activeData.item }
                handleAddItem({ ...item })
                addItem({ ...item })
            } else {
                const { dateStart, dateEnd } = rootItem
                const dates = moveDateOrIntervalDay({ dateStart, dateEnd }, overData.start)
                // const rootItem = calItem.dateEnd ? eventSet.get(calItem.id) : calItem
                if (rootItem.dateEnd && isRecurringEvent(rootItem)) { // move recurring event
                    const { cron } = rootItem

                    //#region init variables
                    const curEvent = rootItem
                    const newStart = overData.start as number // !!! hopefully
                    const beforeMoveDate = new Date(curEvent.dateStart), movedDate = new Date(newStart)
                    const ogDayOfWeek = beforeMoveDate.getDay() + 1, movedDayOfWeek = movedDate.getDay() + 1
                    const cronParser = new EventCronParser(cron)
                    //#endregion

                    cronParser.setUTCHours([movedDate.getUTCHours()], [movedDate.getUTCMinutes()], true) // set hours first, then set day of week

                    if (ogDayOfWeek !== movedDayOfWeek) {
                        handleCronDayOfWeekChange(cronParser, ogDayOfWeek, movedDayOfWeek)
                    }

                    editItem(rootItem.id, {
                        cron: cronParser.getCron(),
                        ...(newStart < new Date(rootItem.dateStart).getTime() && { dateStart: newStart }),
                        // ...(isAfter(newStart + duration, ogEvent.dateEnd) && { dateEnd: newStart + duration }), // dateEnd is more strict limit then dateStart for recurring events
                    })
                    const { duration } = cronParser.parsedCron
                    const dateStart = newStart < new Date(rootItem.dateStart).getTime() ? newStart : rootItem.dateStart
                    const dateEnd = isAfter(newStart + duration, rootItem.dateEnd) ? newStart + duration : rootItem.dateEnd; // dateEnd is more strict limit then dateStart for recurring events

                    handleDateChange(rootItem.id, {
                        cron: cronParser.getCron(),
                        dateStart,
                        dateEnd
                    })
                    return
                }
                handleDateChange(rootItem.id, { ...dates })
                editItem(rootItem.id, { ...dates })
            }
        },
        onDragCancel(event) { },
    });

    const rows = useMemo(() => {
        const weeksInCal = Math.ceil(days / 7)
        const weekGroups = groupBy([...calEvents, ...tasks], (e) => startOfWeek(e.dateStart, { weekStartsOn }).getTime())

        const rows = range(weeksInCal).map((i) => {
            const start = startOfWeek(addWeeks(startDate, i), { weekStartsOn }).getTime()

            return (
                <Row layoutUnit={layoutUnit} layoutVal={layoutVal} hasExtraSpace={layoutUnit !== 'month'} calendarId={calendarId} key={'row:' + i} activeDate={activeDay} start={start} handleDoubleClick={handleDoubleClickDate} >
                    {weekGroups[start]?.sort((a, b) => {
                        if (a.priority !== b.priority) return (b.priority || 1) - (a.priority || 1)
                        return a.dateStart - b.dateStart
                    }).map((item, i) => {
                        const daysSpanned = item.dateEnd ? numberOfDaysSpanned(item.dateStart, item.dateEnd) : 1
                        const style = {
                            gridColumn: `${getPositionOfDayOfWeek(new Date(item.dateStart).getDay(), weekStartsOn)} / span ${daysSpanned}`,
                        }
                        return <Item
                            rootItem={'dateEnd' in item ? eventSet.get(item.id) : item}
                            contextMenu={contextMenu} Wrapper={Wrapper}
                            style={style}
                            calendarId={calendarId}
                            isActive={!!active && active === (eventSet.get(item.id)?.id || item.id)}
                            key={`item-${i}:${item.id}`}
                            item={item}
                        />
                    }) || []}
                </Row>
            )
        })

        return rows;
    }, [layoutUnit, layoutVal, startDate, calEvents, tasks])

    return (
        <Calendar>
            <DayOfWeekLabels firstDayOfWeek={(startDate.getDay() % 7) as DayOfWeek} days={Math.min(7, days)} />
            {rows}
            <DragOverlay className={css({ zIndex: 100 })}>
                {activeDragItem !== null ? (
                    <ItemStyle
                        style={categoryColorDynamic(activeDragItem.colorNum)}
                        className={css({ zIndex: 100 })}
                    >{activeDragItem.name}</ItemStyle>
                ) : null
                }
            </DragOverlay>
        </Calendar>
    )
}

export default DayGrid

const Calendar = styled('div', {
    base: {
        gridArea: "calendar",
        gap: "0.1rem",
        height: "100%",
        boxSizing: "border-box",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr",
        background: "$outline",
        border: "$border",
        borderTop: "none",
        overflowWrap: "break-word",
        gridAutoRows: "1fr",
        gridTemplateRows: "max-content",
        minWidth: 0,
        minHeight: 0
    }
})